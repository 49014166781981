import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useLoginMutation } from "store/services/authsApi";
import AuthsView from "views/Auths";

function Auths() {
  const [login, loginStates] = useLoginMutation();
  const location = useLocation();
  const navigate = useNavigate();

  const authenticationSession = async (requestBody) => {
    try {
      await login(requestBody).unwrap();
      navigate("/welcome");
    } catch (error) {
      // No action needed, error handling not required here
    }
  };

  useEffect(() => {
    if (location && location.search) {
      const requestBody = {
        survey_token: location.search.split("=")[1],
      };
      authenticationSession(requestBody);
    }
  }, []);

  return <AuthsView loginStates={loginStates} hasSurveyToken={!!(location && location.search)} />;
}

export default Auths;
