import { Box, Button, Loader, Typography } from "components";
import PropTypes from "prop-types";
import React from "react";
import { useNavigate } from "react-router-dom";

function Auths({ loginStates, hasSurveyToken }) {
  const navigate = useNavigate();

  const retry = () => {
    navigate(0);
  };

  return (
    <Box className="centered flex-container-column">
      <Box>
        <Loader type="circular" toggle={loginStates.status === "pending"} />
      </Box>
      {loginStates.isError ? (
        <Typography variant="h3">{loginStates.error.message}</Typography>
      ) : null}
      {!hasSurveyToken && (
        <Typography variant="h3">
          Uh-oh! This doesn&apos;t seem like a valid link. Please check and try again. Thanks!
        </Typography>
      )}
      {loginStates.isError && (
        <Button variant="text" color="info" onClick={retry}>
          try again
        </Button>
      )}
    </Box>
  );
}

Auths.defaultProps = {};

Auths.propTypes = {
  loginStates: PropTypes.any.isRequired,
  hasSurveyToken: PropTypes.bool.isRequired,
};
export default Auths;
