import { api } from "config/apiHandler";

const surveys = api.injectEndpoints({
  endpoints: (build) => ({
    getSurvey: build.query({
      query: (surveyToken) => `/surveys?survey_token=${surveyToken}`,

      transformResponse: (baseQueryReturnValue) => {
        if (baseQueryReturnValue.code !== "success") throw new Error(baseQueryReturnValue.message);
        else {
          return baseQueryReturnValue.data;
        }
      },
      transformErrorResponse: (baseQueryReturnValue) => baseQueryReturnValue,
    }),

    feedbacks: build.mutation({
      query: (requestBody) => ({
        url: "/feedbacks",
        method: "POST",
        body: requestBody,
      }),
      transformResponse: async (baseQueryReturnValue) => {
        if (baseQueryReturnValue.code !== "success") throw new Error(baseQueryReturnValue.message);
        else {
          return baseQueryReturnValue.data;
        }
      },
    }),
  }),
  overrideExisting: false,
});

export const { useGetSurveyQuery, useLazyGetSurveyQuery, useFeedbacksMutation } = surveys;
